@media (max-width: 550px){


  .titrePlan{
    color: red;
    font-size: 2rem!important;
  }
  .footer {
    padding: 20px;

    .footer-logo {
      
        width: 10%;
        height: 10%;
    }

    img {
      width: 50%;
    }

    .bloc1{
      margin: 0;
      padding: 0;
      width: 90%;

      .reservation {
        .reservation-text{
            font-size: 5vw;
          }
          .phone-number{
            font-size: 5vw;
          }
          .adress{
            font-size: 4vw;
          }
      }

    }

    .titrePlan{
      color: red;
    }

   .droits {
     font-size: 3vw;}

     h2{
      color: red;
     }


    .footer-content {

      .bloc0{
        height: 50%;
        width: 50%;
      }

      flex-direction: column;
      align-items: center;

      .bloc0, .bloc1, .bloc2 {
        margin: 10px 0;
      }

      .site-map {

        h5 {
          font-size: 1.7rem;
          margin-bottom: 0;
        }
      }

      .social-media {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;
      }
    }
  }
}
