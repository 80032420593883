
// MOBILE
@media (max-width: 760px) {

  .pathDesktop {
    display: none;
  }

  .pathMobile {

    .circle {
    }
  }
    
  // Section acces au restaurant
 .access-section {
  padding-top: 10%;
  margin-bottom: auto;

  .TexteMobile {
    display: hidden;
  }
  

  h2 {
  }

  .pieds {
    height: auto;
    width: 25%;
  }


  .titreVenir{
      font-size: 4.5rem;
  }
     // Texte court pour mobile
     .TexteMobile {
       display: block;
       font-size: 1.8rem;
       width: 90%;
       text-align: justify;
       text-align-last: center;
       margin: auto;
       font-family: 'Montserrat';
        font-weight: 300;
      }
   
 
     // Icone de traces de pas

   
     // Bloc adresse du restaurant
     .adresse {
       font-size: 1.9rem;
       width: 75%;
       padding: 1% 2% 1% 2%;
     }
   
     // Conteneur de la carte et du texte
     .content-wrapper {
       display: flex;
       flex-direction: column;
       background-color: transparent;
       box-shadow: none;
       width: 90%;
   
       // Bloc carte + bloc texte
       .left-column,
       .right-column {
         flex: 1;
         padding: 0 15px;
       }

       .left-column{
       }
 
   
       // Bloc contenant le texte
       .text-section {
         // Titre "Trouvez votre chemin vers le Cro Magnon"
         h3 {
           display: none;
         }
   
         // Texte explication accès au restaurant
         p {
             display: none;
         }
       }
     }
   
     // Bloc carte google map

     .text-section{
      width: 100%;
     }
     .google-map {
         margin-top: 5%;
         height: 200px;
     }

     
   }

   //   Contenu de la modale
.modal {
  border-radius: 25px;
  width: 85%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #e2e2e2, #c9d6ff);

  // Boutton fermer
  button {
    font-family: "Montserrat";
    border-radius: 20px;
    border: none;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  }
}

.modal-image {
  margin: auto;
  width: 100%;
  height: 75vh;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}

.test000{
}
 }
 