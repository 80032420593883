// Bloc Footer
.footer {
  background-color: #1a1a1a; 
  color: white;
  border-radius: 25px 25px 0 0;
  padding: 2%;

  .droits {
    margin-top: 0;
    font-size: 0.8vw;
  }

  // Adresse
  .adress{
    font-size: 1.1vw;
    font-family: 'Montserrat';
    margin-top: 1%;
  }

  // Mini menu
  .menuFooter {
    font-family: 'Montserrat';
    display: flex;
  }

  // Bloc contenu du footer
  .footer-content {
    display: flex;
    align-items: center;

    
  // Bloc infos
 .bloc1{
  border-radius: 30px;
  padding: 1% 5% 1% 5%;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
  background: #232526;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


 }

  // Disposition des blocs et comportement au survol
    .bloc0, .bloc1, .bloc2 {
      flex: 1;
      margin: 10px;

      &:hover {
        transform: translateY(-5px); // Une légère animation au survol
        transition: transform 0.3s ease;
      }
    }

    // Logo du restaurant dans le footer
    .footer-logo {
      width: 50%;
      height: 100%;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1); // Grossissement du logo au survol
      }
    }

    // Bloc réservation
    .reservation {
      display: flex;
      flex-direction: column;
      
      // Texte "Réservez votre table !"
      .reservation-text {
        font-weight: bold;
        font-size: 1.7vw;
        font-family: 'Shark';
      }

      // Numéro de téléphone
      .phone-number {
        font-family: 'Montserrat';
        color: whitesmoke; // Couleur pour ressortir le numéro
        font-size: 1.7vw;
      }


    }

    // Architecture du site
    .bloc2{
    display: flex;
    flex-direction: column;
    align-items: center;
    // Texte plan du site
      h2 {
        margin-bottom: 0;
        font-size: 1.5vw;
        font-family: 'Montserrat';
        color: white;
      }
      // Liste des liens
      ul {
        list-style: none;
        padding: 0;
        margin-top: 0;
        gap: 2%;

        // Liens
        li {

          a {
            color: white;
            transition: color 0.3s ease;
            text-decoration: none;
            list-style: none;

            &:hover {
              color: #007bff; // Changement de couleur au survol
            }
          }
        }
      }
    }
    // Icones réseaux sociaux
    .social-media {
      display: block;
      width: 100%;
      justify-content: center;
      align-items: center;

      a {
        margin-right: 10px;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.1); // Animation des icônes de réseaux sociaux
        }

        img {
          max-height: 30px;
        }
      }
    }
  }
  // Le texte de droits réservés
  span {
    display: block;
    margin-top: 2%;
    font-size: 1vw;
    font-family: 'Montserrat';
  }
}


