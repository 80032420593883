// Barre de navigation flottante
.floating-navbar {
  width: 500px;
  height: 50px;
  background-color: #a26a2a;
  border-radius: 30px;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-shadow: rgba(0, 0, 0, 0.255) 2px 2px 5px 2px;
  z-index: 1000001;

//   Logo restaurant barre de navigation
  img {
    width: 6%;
    height: auto;
  }

//   Liste de navigation
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    list-style: none;
    width: 80%;
    margin: auto;
    font-family: "Shark";
    font-size: 1.5rem;
    
    li {

      a {
        color: white;
        font-weight: bold;
        transition: color 0.3s ease;

        &:hover {
          color: #0b2537;
        }
      }
    }
    // Bouton de réservation
    #tel {
        border-radius: 20px;
        border: none;
        padding:1% 2% 1% 2% ;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
      background: #c9d6ff; /* fallback for old browsers */
      background: -webkit-linear-gradient(
        to right,
        #e2e2e2,
        #c9d6ff
      ); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(
        to right,
        #e2e2e2,
        #c9d6ff
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    //   Survol du bouton de réservation
      &:hover {
        background: #232526; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to right,
          #414345,
          #232526
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to right,
          #414345,
          #232526
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

        a {
          color: white;
        }
      }

      a {
        color: rgb(71, 51, 51);
      }
    }
  }
}

@media (max-width: 1100px) {
  .floating-navbar {
    width: 60%;
    top: 5%;
    ul{
      #tel {
        padding-top: 2% ;
        padding-bottom: 2% ;
        padding-right: 5% ;
        padding-left: 5% ;
      }
    }
 

    .reserve-text {
      display: none; // Cela masquera le texte sur les écrans de petite taille
    }
  
  }
}

@media (max-width: 600px) {
  .floating-navbar {
    width: 80%;
    top: 7%;
    ul{
      #tel {
        padding-top: 2% ;
        padding-bottom: 2% ;
        padding-right: 5% ;
        padding-left: 5% ;
      }
    }
 

    .reserve-text {
      display: none; // Cela masquera le texte sur les écrans de petite taille
    }
  
  }
}



