
@media (max-width: 1100px){

  // Section acces au restaurant
.path-indicators {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  font-family: 'Montserrat';
  font-size: 1.3rem;
  flex-direction: column;
}

.pathMobile {
  display: none;
}

.pathDesktop{
  margin-top: 0;
}

.path-indicator {
  display: flex;
  align-items: center;
  margin: 0 5px;
}

.circle {
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  margin-right: 5px;
}



.circle.walking {
  background-color: rgb(222, 98, 16); /* Vert pour le chemin à pied */
}

.circle.driving {
  background-color: rgb(133, 21, 135); /* Bleu pour le chemin en voiture */
}

  // Section acces au restaurant
  .access-section {
    .pieds {
      height: auto;
      width: 15%;
    }
    
      // Texte court pour mobile
      .TexteMobile {
        display: none;
        
      }
    
      // Titre "Comment venir ?"
      h2 {
        margin-top: 0;
        margin-bottom: 0;
      }
  
    
      // Bloc adresse du restaurant
      .adresse {
        font-size: 2rem;
        margin: auto;
        margin-top: 1%;
        margin-bottom: 2%;
        width: 50%;
        padding: 0.5% 1% 0.5% 1%;
      }
    
      // Conteneur de la carte et du texte
      .content-wrapper {
        width: 85%;
     
    
        // Bloc carte + bloc texte
        .left-column {
    
        }
    
        // Bloc contenant la carte manuscrite
        .hand-drawn-map {
          border: 2px solid white;
          border-radius: 20px;
          background: linear-gradient(to right, #e2e2e2, #c9d6ff);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          width: 100%;
          height: 100%;
  
          // Effet au survol
          &:hover {
            transform: scale(1.05);
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
          }
        }
    
        .right-column{
          flex: 2;
          height: 100%;
        display: flex;
        flex-direction: column;
        }
    
        // Bloc contenant le texte
        .text-section {
          // Titre "Trouvez votre chemin vers le Cro Magnon"
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 2%;
          h3 {
            width: 100%;
            font-size: 2.8rem;
            letter-spacing: -2px;
            font-family: "Montserrat";
            margin-top: 0;
            line-height: 3rem;
            margin-bottom:2%;
          }
    
          // Texte explication accès au restaurant
          p {
            text-align: justify;
            font-size: 1.2rem;
            color: black;
            margin-top: 0;
            line-height: 1.5rem;
          }
        }
      }
    
      // Bloc carte google map
      .google-map {
        width: 100%;
        height: auto;
        border-radius: 15px;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        &:hover {
          transform: scale(1.03);
          box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
        }
    
        iframe {
          width: 100%;
          height: 100%;
          border: none;
        }
      }
    }
    
    // Fond de la modale
    .modal-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
      z-index: 1000;
    
    //   Contenu de la modale
      .modal {
        border-radius: 25px;
        width: 85%;
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: linear-gradient(to right, #e2e2e2, #c9d6ff);
    
        // Boutton fermer
        button {
          font-family: "Montserrat";
          border-radius: 20px;
          border: none;
          box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
    
    