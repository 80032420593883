
// Section accueil
section .header-section {
    background-color: red!important;
    z-index: 20000000!important;
}

.vacances{
    font-weight: 700;
    background-color: #00548E;
    border-radius: 10px;
    color: white!important;
    text-align: right;
    padding-right: 5%;
    margin-top: 0;
}

.header-section {
    height: 100vh;
    background-image: url('../../../public/img/fondnew1.webp');
    background-size: cover;
    background-position-y: -50px;
    background-repeat: no-repeat;
    width: 100%;
    margin: auto;
}

.google-card{
    border-radius: 20px;
    padding: 1rem;
    background: #D3CCE3;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #E9E4F0, #D3CCE3);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #E9E4F0, #D3CCE3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    
    p{
        margin-bottom: 0;
        margin-top: 0;
        font-size: 1.5rem;
        color: black;
    }

    img {
        width: 20px;
    }

    .google-card-content {

        .google-logo{
            width: 40px;
            height: auto;
            margin-bottom: 0;
        }
    }
    &:hover {
        transform: scale(1.05); /* Agrandir la carte au survol */
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Ajouter une ombre au survol */
        background: -webkit-linear-gradient(to right, #D3CCE3, #E9E4F0);
        background: linear-gradient(to right, #D3CCE3, #E9E4F0);
      }
}




.landscapeMobilee{
    display: none;
}

.logoLandscape {
    display: none;
}
// Bouton de réservation
.boutonResaDesktop {
    border-radius: 30px;
    border: none;
    width: 80%;
    padding: 1%;
    font-size: 2vw;
    font-family: 'Shark';
    box-shadow: 0 8px 25px rgba(0,0,0,0.2);
    color: #00548E;
    display: flex;
    justify-content: center;

    // Icone téléphone
    .faPhoneIcon {
        margin-left: 5%;
        animation: fadeIn 2s;
    }

    &:hover {
    }

    h4{color:red;}
}
// Bouton Mobile
.boutonResa {
    display: none;
}

// Bandeau top mobile
.bandeauMobile {
    display: none;
}

.blocSocial{
    display: flex;
    flex-direction: row;
    text-decoration: none;

    img{
        filter: invert(100%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);

    }

}

// Bandeau info top desktop
.bandeau {
    margin: auto;
    padding-top: 3%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    height: 10%;
    animation: fadeIn 2s;

    // Bloc icones social

    .blocTripAvis{
        margin-left: 0;
        padding-left: 0;
    }
    .blocSocial {


        img {
            height: 70px;
            width: auto;
            margin-left: 14px;
            transition: transform 0.3s ease;

      
        }
    }

    .blocSocial {
        img {
            height: 40px;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

// Bloc Logo + Bouton de réservation
.blocBouton {
    width: 38%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: fadeIn 2s;
    margin-bottom: 0;

    // Réservez votre table
    h3{
        font-size: 1.8vw;
        margin-bottom: 1%;
        letter-spacing: 0.3vw;
        margin-top: 0;
        text-transform: capitalize;
        font-family: 'Hogfish';
        text-shadow: 15px 2px 25px rgba(0, 0, 0, 0.2);
        color: #022844;

    }

    // Réservation conseillée
    h4 {
        font-size: 0.9vw;
        letter-spacing: 2px;
        width: 105%;
        margin-top: 2%;
        font-family: 'Montserrat';
        font-weight: 400;
        text-align: center;
        text-align-last: center;
    }
}

// Bloc contenu de l'accueil
.blocCentral {
    width: 100%;
    height: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

// Bloc texte home
.content2 {
    background-image: url(../../../public/img/courbe.svg);
    background-size: cover;
    display: flex;
    text-align: right;
    align-items: flex-end;
    justify-content: space-between;
    padding-left: 4%;
    padding-right: 3%;
    height: 22vw;
    
    h2 {
        color: #043e67;
        font-size: 3.5vw;
        line-height: 5vw;
        font-family: 'Hogfish';
        text-shadow: 15px 2px 25px rgba(0, 0, 0, 0.2);
        animation: fadeIn 2s;
        margin-bottom: 0;
    }
    // Logo Cro Magnon
    img {
        height: 20vw;
        width: auto;
        margin-top: 0;
        margin-bottom: 2%;
        margin-left: 2%;
        filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.456));
    }
    
    // Texte horaires
    p {
        font-size: 1.3vw;
        letter-spacing: 3px;
        margin-top: 0;
        font-weight: 00;
        color: #00548E;

        strong {
            color: #01365c;
        }
    }
}
