.stacked-gallery {
  position: relative;
  margin: 20px auto;
  width: 90%;
  height: 45vw;
  max-width: 700px;
  max-height: 500px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  .images-stack {
    width: 100%;
    height: 100%;
    position: relative;

    .gallery-image {
      width: 400px;
      max-width: 100%;
      height: 100%;
      border-radius: 15px;
      object-fit: cover;
      display: block;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity 0.5s ease;

      &.active {
        opacity: 1;
        position: relative;
      }
    }
  }

  .gallery-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 2rem;
    color: white;
    z-index: 2;

    &.left {
      left: 10px;
    }

    &.right {
      right: 10px;
    }
  }

  
}

.Modal {
  position: absolute;
  display: flex;

  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  padding: 0;
  overflow: hidden;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
}

.close-modal {
  position: absolute;
  top: 20%;
  right: 10px;
  font-size: 2.5rem;
  color: white;
  cursor: pointer;
}

.modal-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  font-size: 3rem;
  color: #333;
  z-index: 1010;

  &.left {
    left: 20px;
  }

  &.right {
    right: 20px;
  }
}

.modal-image {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}
