// ErrorPage.scss
.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  
    h1 {
      font-size: 5rem;
      margin-bottom: 0.5rem;
    }
  
    p {
      font-size: 1.5rem;
    }

    .logo404 {
        width: 300px;
        height: auto;
     
    }
  }
  