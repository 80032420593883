

.active-section-title-mobile {
  display: none; /* Cachez le titre mobile par défaut */
}
.header-container{
.download-pdf-button{
  margin-top: 2%;
  text-decoration: none;
  color: beige;
  background-color: black;
  padding: 0.5% 2% 0.5% 2%;
  border-radius: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);

}

.blocMenuDownload{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3%;
}
}

.menuPDF{
  display: none;
  height: 600px;
  width: 100%;
}



@keyframes bounce{
  from {top: 106px;}
  50%  {top: 86px;}
  to   {top: 106px;}
}

.loading-spinner{
  display: flex;
  align-items: center;
  justify-content: center;
  animation: bounce 0.7s ease infinite;

  img {
    margin-top: 12%;
}

}

// Conteneur du menu
.menu-container {
  max-width: 1800px;
  margin: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;

  // Titre notre carte
  h2 {
      font-size: 6rem;
      margin-top: 0;
      margin-bottom: 1%;
      animation: fadeInDown 1s ease-out forwards;
      text-shadow: 0 0 15px rgba(0, 0, 0, 0.1); 

  }

  // Logo du restaurant
  .restaurant-logo-menu {
    height: auto;
    width: 10%;
      margin-bottom: 1%;
      animation: fadeIn 1s ease-out forwards;
      filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.456));

  }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInDown {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

// Menu de navigation entre les sections de plats
.sections-nav {
  display: flex;
  justify-content: center;
  width: 70%;
  margin-top: 3%;
  // Masquage du titre mobile
 
// Boutton de navigation
  .section-button {
    border: none;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      color: black;
      font-family: 'Montserrat';
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);

      // Au survol du menu
      &:hover, &.active {
          background-color: #bb884e;
          color: white;
          transform: scale(1.05);
          font-weight: 700;
      }
      .icon {
          margin-right: 5px;
          transition: transform 0.3s ease-in-out;

          &:hover {
              transform: rotate(360deg);
          }
      }
  }
}

// Texte de chaque card
.menuItemsText{
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  display: flex;
  align-items: flex-start;
}

// Ingrédients et infos du plat
.menu-item-details{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-left: 0;

  .menu-item-1{
    width: 95%;
  }



}

// Card de chaque plat
.menu-items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 70%;
  margin: 3% auto;
  height: auto;
  justify-content: center;

  .menu-item-2{
    width: 20%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    right: 5%;
    top: 8%;
    font-family: 'Montserrat';
    font-size: 1.5rem;
    font-weight: 700;
    background-color: #bb884ed3;
    border-radius: 15px;
    color: white;
    padding: 1% 2% 1% 2%;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);

  }

  // Card
  .menu-item-card {
      border-radius: 20px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      width: calc(33.333% - 20px);
      box-shadow: 0 5px 20px rgba(0,0,0,0.15);
      transition: all 0.3s ease-in-out;
      background: #ECE9E6; 
      background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);
      background: linear-gradient(to right, #FFFFFF, #ECE9E6);
      height: 180px;
      position: relative;
      padding-bottom: 1%;

      &:hover {
          box-shadow: 0 8px 25px rgba(0,0,0,0.2);
          transform: translateY(-10px);
      }

      // Image du plat
      .menu-item-image {
          width: 100%;
          max-width: 100%;
          height: 50%;
          object-fit: cover;
          transition: all 0.3s ease-in-out;
      }

      // Nom du plat
      .menu-item-name {
        margin-bottom: 0;
        margin-top: 4%;
        padding-left: 3%;
        padding-right: 3%;
        font-size: 1.4rem;
        text-align: left;
        line-height: 1.7rem;
        font-family: 'Montserrat';
        font-weight: bold;
        display: flex;
      }

      // Quantité du plat
      .menu-item-quantity {
          font-weight: 600;
          font-size: 1.5rem;
          margin-bottom: 0;
          margin-top: 1%;
          width: 100%;
          text-align: start;
          padding-left: 3%;

      }

      // Prix du plat
      .menu-item-price {
        margin: 0;
        padding: 0;

      }

      // Informations supplémentaires du plat
      .menu-item-additional {
          text-align: left;
          font-size: 1.2rem;
          margin-top: 0;
          margin-bottom: 0;
          width: 90%;
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          margin-top: 1%;
          padding-left: 3%;
          padding-right: 3%;
      }
  }
}




