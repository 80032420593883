// Section à propos du restaurant
.about-section {
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  justify-content: space-between;
  margin: auto;
  border-radius: 20px;
  padding-top: 7%;

  h1 {
    font-size: 6rem;
    margin-bottom: 0;
    margin-top: 0;
  }
  


  // Bloc du contenu de la page à propos
  .content {
    margin-bottom: 0;
    width: 70%;

    // Image du restaurant
    .restaurant-logo {
      max-width: 100%;
      height: auto;
      animation: bounceIn 1s ease forwards;
      margin-top: 5%;
    }

    // Titre "Le Cro Magnon"
    h2 {
      font-size: 5.8rem;
      margin-bottom: 0;
      margin-top: 0;
    }

    // Phrase réservation
    h3{
      width: 80%;
      margin: auto;
      padding: 1% 0 2% 0;
      border-radius: 20px;
      text-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
      font-family: 'Shark';
      font-size: 2.2rem;
      color: rgb(38, 38, 38);
    }

    // Titre "Restaurant"
    h1 {
      margin-bottom: 0;
      font-family: 'Montserrat';
      font-weight: 100;
      margin-top: 1%;
      font-size: 4rem;
    }

    .blocTextes{
    // Texte de présentation du restaurant
    .text-long {
      font-size: 1.5rem;
      line-height: 2.2rem;
      margin: auto;
      text-align: justify;
      text-align-last: center;
      width: 95%;
      margin-bottom: 5%;
      color: #454545;
      padding: 3%;
      border-radius: 30px;
      box-shadow: 0 0 25px rgba(103, 100, 100, 0.1);
      background: #ece9e6b8;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #ffffffc8, #ece9e6c8);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

strong {
  color:rgb(0, 0, 0);
  font-weight: 500;
}

    }
  }

    // Texte pour mobile
    .text-short {
      display: none;
    }

    .gallery-mobile {
      display: none;
    }
  }
}

// Bloc navigation entre les sections
.blocLogoNoir {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  height: 50px;
  width: 50%;
  margin: auto;
  margin-bottom: 2%;
  background: linear-gradient(to right, #414345, #232526);
  padding-left: 3%;
  padding-right: 3%;
  margin-top: 1%;


  // Logo localisation
  .locationIcon {
    filter: invert(100%) hue-rotate(86deg) brightness(118%) contrast(119%);
  }

  // Icones réseaux sociaux et navigation
  img {
    height: 30px;
    width:auto;
  }


  // Titre des icones au survol
  .icon-title {
    display: none; // Masquer initialement le titre
    white-space: nowrap;
    color: white; // Assurez-vous que la couleur est visible sur votre fond
    font-size: 14px;
    text-align: center;
    background-color: orange;
    border-radius: 20px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  }
}



