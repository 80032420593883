

@media (max-width: 600px)
{

  .menuPDF{
    display: block;
    height: 600px;
    width: 95%;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .active-section-title-mobile {
    display: none; /* Cachez le titre mobile par défaut */
  }



  
  .loading-spinner{
    display: none;
  }
  
  // Conteneur du menu
  .menu-container {
    .restaurant-logo-menu {
      height: auto;
      width: 25%;
        margin-bottom: 1%;
        animation: fadeIn 1s ease-out forwards;
        filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.456));
  
    }


  }

  
  // Menu de navigation entre les sections de plats
  .sections-nav {
    display: none;
   
  // Boutton de navigation
    .section-button {
display: none;
    }
  }
  
  // Texte de chaque card
  .menuItemsText{
    display: none;
  }
  
  // Ingrédients et infos du plat
  .menu-item-details{
    display: none;

  
    .menu-item-1{
      display: none;

      
    }
  
  
  
  }
  
  // Card de chaque plat
  .menu-items {
    display: none;

  
    // Card
    .menu-item-card {
      display: none;

  
      
    }
  }
  
  
  
  
  

}

@media (max-height: 600px) {
  .pdf-viewer {
    .pdf-iframe {
      max-height: 100%;
    }
  }
}

