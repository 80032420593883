// TABLETTE
@media (max-width: 1025px) and (orientation: portrait){
    .boutonResaDesktop {
        display: none;
    }

    .heures {
        text-align: center;
    }

    .vacances{
        font-weight: 700;
        background-color: #00548E!important;
        border-radius: 10px;
        color: white!important;
        text-align: center;
        padding-right: 0;
        margin-top: 0;
    }

    .header-section {
        height: 95vh;
        max-height: 1500px;
        display: flex;
        flex-direction: column;
        background-image: url('../../../public/img/background_ete.jpg');
        background-position: center;
        background-size: cover;
        background-position-y: -1px;
        width: 100%;
    }

    .boutonResa {
        display: block;
        margin-bottom: 0;
        border-radius: 20px;
        padding: 2% 5% 2% 5%;
        border: none;
        font-family: "Montserrat";
        font-size: 2.5rem;
        background: #C9D6FF;
        background: -webkit-linear-gradient(to right, #E2E2E2, #C9D6FF);
        background: linear-gradient(to right, #E2E2E2, #C9D6FF);
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
        color: black;
    }

    .bandeauMobile {
        display: flex;
        flex-direction: row;
        width: 90vw;
        align-items: center;
        justify-content: space-between;
        margin: auto;
        margin-bottom: 2%;

      

        .google-card{
            display: flex;
            justify-content: center;
            align-content: center;

            .google-card-content{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .google-logo-mobile{
                height: 30px;
                width: auto;
            }
        }
        

        .blocSocial2 {
            display: flex;
            flex-direction: row;
            height: 40px;
            margin-right: 0;
            padding-right: 0;

            img {
                height: 40px;
                width: auto;
                margin-left: 15px;
                filter: invert(100%);
            }


        }
    }

    .bandeau {
        display: none;
    }

    .content2 {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        overflow-x: hidden;
        height: 95vh;
        margin-bottom: -11%;
        

        h2 {
            font-size: 8vw;
            line-height: 10vw;
            text-align: center;
            width: 100%;
        }

        p {
            font-size: 2.3vw;
        }


    }
    

    .blocBouton {
        h4 {
            display: none;
        }

        h3 {
            display: none;
        }

        width: 80%;
    }
}


@media (max-width: 1400px) and (orientation: landscape){
    .content2{
        height: 23.5vw;
    }
    }