// Pour mobile
@media (max-width: 600px)
{



.about-section{
  padding-top: 10%;
  margin-bottom: 5%;


  

  .content {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 0;

    .blocTextes {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      .text-short {

        width: 95%;
        text-align: justify;
        text-align-last:center ;
        color: black;
        margin-bottom: 0;
        margin-top: 2%;
        display: block;
        font-size: 1.85rem;
        line-height: 2.5rem;
        font-weight: 300;
        text-shadow: 0 0 25px rgba(0, 0, 0, 0.5);


      }

    
    
      
    }

   .pieds {
    width: 20%;
    height: 20%;
   }


    // Titre "Le Cro Magnon"
    h1 {
      font-size: 5rem;
    }
    // Phrase réservation
    h4{
     font-size: 1.4rem;
     width: 95%;
     margin-bottom: 0;
     margin-top: 2%;
     text-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
     color: #022640;

    }



    h2{
      font-size: 5rem;
    }
   
    .blocLogoNoir{
      display: none;
    }

    .gallery-desktop{
      display: none;
    }

    .restaurant-logo{
      width: 150px;
      max-width: 100%;
      height: auto;
    }

  



    .gallery-mobile {
      display: flex;
    }

    .text-long {
      display: none;
    }

  }


}
}

@media (max-width: 1200px) and (orientation: landscape){
  .blocLogoNoir{
      display: none;
  }
  }



