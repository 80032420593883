




@media (max-width: 550px) and (orientation: portrait){

    .landscapeMobilee
    {
        display: none;
    }
    .boutonResaDesktop{
        display: none;
    }

    .main-text-and-logo {

    }


    .blocCentral{
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .heures {
        background: #D3CCE3;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #E9E4F0, #D3CCE3);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #E9E4F0, #D3CCE3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        height: 50px;
        margin-top: 0!important;
        padding: 5%;
        border-radius: 15px;
        margin-bottom: 50px;
    }

    .vacances{
        font-weight: 700;
        background-color: #00548E;
        border-radius: 10px;
        color: white!important;
        text-align: right;
        padding-right: 5%;
        margin-top: 0;
        margin-bottom: 2%!important;
    }



    .header-section {
        position: relative;
        background-image: url('../../../public/img/background_ete.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 98vh;
        width: 100%;
    
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 255, 0.1); // Couleur bleue avec transparence
            -webkit-backdrop-filter: blur(3px); // Flou pour Safari
        }
    }

 

    .boutonResa{
        display: block;
        margin-bottom: 0;
        border-radius: 5px;
        padding: 2% 5% 2% 5%;
        border: none;
        font-family: "Montserrat";
        background: #C9D6FF;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #E2E2E2, #C9D6FF);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #E2E2E2, #C9D6FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
border-radius: 15px;
color: black;

    }

    .bandeauMobile {
        display: none;

    }

    .status-text {
        color: white!important;

    }

    .bandeau {
        display: flex;
        width: 90%;
        display: flex;
        padding-top: 28%;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 0;
        background-image: none;
        z-index: 1000;

    }

    .blocSocial {
        filter: none;
        transition: transform 0.3s ease, box-shadow 0.8s ease;
        background-color: #00548E;
        padding: 4%;
        border-radius: 20px;
        display: flex;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
        background: #D3CCE3;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #E9E4F0, #D3CCE3);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #E9E4F0, #D3CCE3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */



        

    }

  

    .content2 {
        
        display: flex;
        height: 100vh;
        width: 90%;
        align-items: center;
        padding-top: 15%;
        justify-content: center;
        background-image: none;
        margin: auto;
        gap: 0;


  
        h2 {
            display: none;
            width: 100%;
            color: white;
            margin-bottom: 5%;
            text-shadow: 0 0 15px rgba(0, 0, 0, 0.8); // Une légère ombre pour donner de la profondeur

        }
        p{
            margin-top: 0;
            font-size: 1.7rem;
            text-align: center;
            color: black;

            strong {
                color: rgb(0, 0, 0);
            }
        }

        img {
            height: 100%;
            width: 60%!important;
        }

        h4{
            display: none;
        }
    }

    .blocBouton {
        margin-bottom: 5vw;
        height: auto;
        width: 100%;
        margin-top: 0;

    button{
        margin-top: 2vw;
        font-size: 4rem;
        border-radius: 20px
    }

   
    }


   


    
};

@media (max-width: 1020px) and (orientation: landscape){


    .header-section {
        margin: 0;
        padding: 0;
        margin-top: 0;
        height: 100%;
        background-position: center;

        .landscapeMobilee {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 95vh;
            padding-top: 5%;
            background-color: rgba(7, 7, 74, 0.48);


            button{
                margin-top: 0;
                margin-bottom: 1%;     
            border: none;
            font-size: 2.3rem;
            font-family: 'Montserrat';
            border-radius: 20px;
            color: white;
        }

        .boutonResaLandscape{
            color: black;
            background-color: blanchedalmond;
            padding: 1%;
            border-radius: 15px;
            margin-bottom: 1%;
        }
    

            h2{
                margin-top: 0;
                margin-bottom: 0;
                width: 100%;
                text-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
                color: white;
            }

            p{
                margin-top: 2%;
                font-size: 1.6rem;
                color: white;
            }

            .logoLandscape {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 35%;
                width: auto;

                
        
        
            }
        }

    .content2 {
        display: none;
    }

    .blocBouton {
        display: none;
    }


    .bandeauMobile{
        display: none;
    }

    .bandeau {
        display: none;
    }
} };
