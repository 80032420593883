// Section acces au restaurant
.path-indicators {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
  font-family: 'Montserrat';
  font-size: 1.2rem;
}

.pathMobile {
  display: none;
}

.pathDesktop{
  margin-top: 0;
  margin-bottom: 1%;
}

.path-indicator {
  display: flex;
  align-items: center;
  margin: 0 5px;
}

.circle {
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  margin-right: 5px;
}

.circle.walking {
  background-color: rgb(222, 98, 16); /* Vert pour le chemin à pied */
}

.circle.driving {
  background-color: rgb(133, 21, 135); /* Bleu pour le chemin en voiture */
}

.access-section {
  padding-top: 2%;
  width: 100%;
  max-width: 1920px;
  margin: auto;
  margin-top: 3%;
  height: 100%;
  // Texte court pour mobile
  .TexteMobile {
    display: none;
    
  }

  // Titre "Comment venir ?"
  h2 {
    font-size: 6rem;
    margin-top: 0;
    margin-bottom: 0;
  }
  // Icone de traces de pas
  .pieds {
    width: 140px;
    max-width: 100%;
    height: auto;
    filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.456));
  }

  // Bloc adresse du restaurant
  .adresse {
    color: white;
    font-size: 2.35rem;
    margin: auto;
    margin-top: 0.5%;
    margin-bottom: 2%;
    background: #141e30; // fallback for old browsers
    background: -webkit-linear-gradient(
      to right,
      #243b55,
      #141e30
    ); // Chrome 10-25, Safari 5.1-6
    background: linear-gradient(
      to right,
      #243b55,
      #141e30
    ); // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
    border-radius: 20px;
    width: 35%;
    padding: 0 1% 0% 1%;
  }

  // Conteneur de la carte et du texte
  .content-wrapper {
    background-color: white;
    display: flex;
    text-align: left;
    width: 70%;
    margin: auto;
    padding: 2%;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
    gap: 3%;
    margin-top: 4%;
    height: auto;
    // Bloc carte + bloc texte
    .left-column {
      flex: 1;
      padding: 0 15px;
      height: auto!important;
    }

    // Bloc contenant la carte manuscrite
    .hand-drawn-map {
      border: 2px solid white;
      border-radius: 20px;
      background: linear-gradient(to right, #e2e2e2, #c9d6ff);
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      width: auto;
      height: 100%;
      display: flex;
align-items: center;
justify-content: center; 
padding-bottom: 4%; 

img{
  height: auto;
  width: 100%;
}

      // Effet au survol
      &:hover {
        transform: scale(1.05);
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
      }
    }

    .left-column {
      flex: 1;
      margin-left: 0;
      height: 100%;
    }

    .right-column{
      flex: 2;
      height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    }

    // Bloc contenant le texte
    .text-section {
      // Titre "Trouvez votre chemin vers le Cro Magnon"
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 2%;
      h3 {
        width: 100%;
        font-size: 4rem;
        letter-spacing: -2px;
        font-family: "Montserrat";
        margin-top: 0;
        margin-bottom: 2%;
        line-height: 4.5rem;
      }

      // Texte explication accès au restaurant
      p {
        text-align: justify;
        color: black;
        line-height: 2rem;
        font-size: 1.4rem;
        margin-top: 2%;
      }
    }
  }

  // Bloc carte google map
  .google-map {
    height: auto;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    margin-bottom: 0;
    &:hover {
      transform: scale(1.03);
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    }

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}

.modal-image {
  margin: auto;
  width: 50%;
  height: 50%;
  object-fit: cover;
  box-shadow: none;

}
// Fond de la modale
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  z-index: 1000;
  box-shadow: none;



//   Contenu de la modale
  .modal {
    border-radius: 25px;
    width: 85%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right, #e2e2e2, #c9d6ff);

    // Boutton fermer
    button {
      left: 15px;
      top: 15px;
      position: absolute;
      font-family: "Montserrat";
      border-radius: 20px;
      border: none;
      margin-bottom: 3%;
    }
  }

  
}

