// Gallery version desktop
.gallery {
    display: flex;
    width: 100%;
    margin: auto;

    & > .gallery_column {
        padding-left: 5px;
        padding-right: 5px;
    }
    // Images de la gallery
    .gallery-item {
        margin-bottom: 5%; 

        // Réglage des images de la gallery
        img {
            width: 300px;
            max-width: 100%;
            height: auto;
            box-shadow: 0 5px 20px rgba(0,0,0,0.15);

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

// Modale pour afficher les photos en grand
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 25px;
    width: 90%;
    height: 90%;
}

// Fond de la modale
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
}

// Bouton de fermeture de la modale et de navigation
button {
    margin: 0 5px;
    padding: 5px 10px;
    border-radius: 10px;
}
// Contenu de la modale
.modal-content {
    position: relative;
    height: 100%;
    display: flex;
        align-items: center;
        justify-content: center;
    // Comportement de l'image dans la modale
    img {

        width: auto; 
        height: 100%; 
        object-fit: cover; 
    }
}

// Bouton de la modale
.close-button,
.nav-button {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    border: none;
    color: white;
    font-size: 3em;
    cursor: pointer;
}
// Bouton de fermeture de la modale
.close-button {
    top: 20px;
    right: 20px;
}

// Boutons de navigation
.nav-button {
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
    font-size: 2.5em;
}

// Bouton précédent 
.prev-button {
    left: 1%;
}
// Bouton suivant
.next-button {
    right: 1%;
}

@media (max-width: 1100px) {
    .gallery {
        width: 80%;
    }

}