
@media (max-width: 1100px) {
  .active-section-title-mobile {
      display: none; /* Cachez le titre mobile par défaut */
    }
    
    // Conteneur du menu
    .menu-container {

    
    
    
      // Logo du restaurant
      .restaurant-logo-menu {
          width: 10%;
      }
    }
    
    // Menu de navigation entre les sections de plats
    .sections-nav {
      width: 85%;
      // Masquage du titre mobile
     
    // Boutton de navigation
      .section-button {
          font-size: 1rem;
      }
    }

    .menu-container{
      h2 {
        font-size: 5.5rem;
      }
    }
    

    
    // Ingrédients et infos du plat
    .menu-item-details{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 96%;
      margin-left: 0;
      padding-left: 0;
      margin: auto;
    
      .menu-item-1{
        width: 90%;
      }
    
      .menu-item-2{
        width: 20%;
      }
    
    }
    
    // Card de chaque plat
    .menu-items {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      width: 90%;
      margin: 3% auto;
      height: auto;
    
      // Card
      .menu-item-card {
          border-radius: 20px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          width: calc(33.333% - 20px);
          box-shadow: 0 5px 20px rgba(0,0,0,0.15);
          transition: all 0.3s ease-in-out;
          background: #ECE9E6; 
          background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);
          background: linear-gradient(to right, #FFFFFF, #ECE9E6);
          height: 175px;
    
          &:hover {
              box-shadow: 0 8px 25px rgba(0,0,0,0.2);
              transform: translateY(-10px);
          }
    
          // Image du plat
          .menu-item-image {
            width: 100%;
            max-width: 100%;
              height: 50%;
          }
    
          // Nom du plat
          .menu-item-name {
              font-size: 1.65rem;
          }
    
          // Quantité du plat
          .menu-item-quantity {
              font-size: 1.4rem;
    
          }
    
          // Prix du plat
          .menu-item-price {
              font-size: 1.4rem;
            
          }
    
          // Informations supplémentaires du plat
          .menu-item-additional {
              font-size: 1.3rem;
          }
      }
    }
    

    
    
}