@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.App {


  position: relative;
  text-align: center;
  width: 100%;
  margin: auto;

.initial {
  opacity: 0;
  transform: translateY(20px);
  visibility: hidden;
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

// Style quand la section entre en vue
.animate {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

}

section{

}

.App::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../public/img/vertical.webp');
  background-size: cover;
  background-repeat: repeat;
  opacity: 0.15; /* Ajustez cette valeur selon vos besoins */
  z-index: -1;
}




.separator {
  border: 2px solid #353935;
  width: 80%;
  border-radius: 100%;
  margin: auto;
  margin-bottom: 3%;
  margin-top: 3%;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'Lost Emerald';
  src: url('../public/fonts/LostEmerald.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hogfish';
  src: url('../public/fonts/Hogfish.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
   font-display: swap;
}


  @font-face {
  font-family: 'Aptly';
  src: url('../public/fonts/AptlyRegular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
   font-display: swap;
}

@font-face {
  font-family: 'AptlyLight';
  src: url('../public/fonts/AptlyLight.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
   font-display: swap;
}

@font-face {
  font-family: 'Shark';
  src: url('../public/fonts/Shark.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  
}

.HeaderSection {
  max-width: 1400px;
}

body {
  width: 100%;
  margin: auto;
  text-decoration: none;

  
  h1, h2,h3 {
    font-family: 'Shark';
    color: rgb(31, 29, 29);

  }

}

a {
  text-decoration: none;

 
}






.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



html {
  font-size: 62.5%; /* Cette définition rend 1rem équivalent à 10px */
}

body {
  font-size: 1.6rem; /* Cela équivaut à 16px */
}

h1 {
  font-size: 5.9rem; /* Cela équivaut à 59px */
  font-size: clamp(2.5rem, 5vw, 5.9rem); /* Cela assure que la taille de la police est responsive et reste entre 25px et 59px */
}

h2 {
  font-size: 3.3rem; /* Cela équivaut à 33px */
  font-size: clamp(1.8rem, 3vw, 3.3rem); /* Cela assure que la taille de la police est responsive et reste entre 18px et 33px */
}

p {
  font-size: 1.8rem; /* Cela équivaut à 18px */
  font-family: 'Montserrat';
  font-size: clamp(1.4rem, 1.5vw, 1.8rem); /* Cela assure que la taille de la police est responsive et reste entre 14px et 18px */
}

/* Pour les petits écrans */
@media (max-width: 850px) {
  html {
    font-size: 50%; /* Réduire la taille de base de la police pour les petits écrans */
  }

  .App {


    position: relative;
    text-align: center;
    width: 100%;
    margin: auto;
    text-decoration: none;
  
  .initial {
    opacity: 0;
    transform: translateY(20px);
    visibility: hidden;
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  }
  
  // Style quand la section entre en vue
  .animate {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
  
  }
  
  .App::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../public/img/mobilefond.webp');
    background-size: contain;
    background-size: 100%;
    background-repeat: repeat;
    opacity: 0.2; /* Ajustez cette valeur selon vos besoins */
    z-index: -1;
  }
}
