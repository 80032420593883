/* TripSectionResponsive.scss */

.slider-container {
  height: auto;
  width: 100%;
  margin-bottom: 5%;
  border-radius: 25px;

  @media (max-width: 760px) {
    margin-bottom: 3%;
  }
}

.top-content {
  margin-top: 3%;

  h2 {
    font-size: 4rem;
    font-family: 'Montserrat';
    margin-top: 0;
    font-weight: 200;
    text-align: center;
  }

  img {
    width: 6%;
    margin-bottom: 0;
    display: block;
    margin: 0 auto;
  }

  @media (max-width: 1068px) {
    img {
      width: 10%;
    }
  }

  @media (max-width: 760px) {
    img {
      width: 15%;
    }

    h2 {
      font-size: 3.5rem;
    }
  }
}

.simple-slider {
  margin-bottom: 2%;
  height: auto;
  overflow: hidden;

  .slick-slide {
    transition: transform 0.3s ease;
    opacity: 0.6;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 90%;

    img {
      padding: 4%;
      width: 100%;
      margin: auto;
      padding: 2%;
      background-color: white;
      border-radius: 25px;
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    }

    &.slick-active {
      opacity: 1;
      transform: scale(1.05);
    }
  }

  .slick-dots li button:before {
    color: #000000;
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
    color: #000;
  }

  @media (max-width: 1068px) {
    width: auto;
    .slick-slide {
      width: 80%;
      img {
        width: 75%;
      }
    }
  }
}

.reviews-stack {
  width: 100%;
  margin-bottom: 5%;

  img {
    width: 90%;
    margin-bottom: 2%;
    padding: 2%;
    background-color: white;
  }

  @media (max-width: 760px) {
    img {
      width: 100%;
      margin-bottom: 3%;
    }
  }
}

/* Style pour la modale */
.modal-content2 {
  position: relative;
  width: 90%;
  margin: 0 auto;
  top: 20%;
  bottom: 50;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centrer horizontalement */

  img {
    max-width: 100%;
    height: auto;
    max-height: 90vh;
    border-radius: 25px;
    object-fit: contain;
  }

  button {
    margin-top: 10px;
    display: none;
  }

  @media (min-width: 760px) {
    /* Styles pour le mode paysage */
    max-width: 80%;
    margin-top: 5vh;
  }
}

.modal-overlay2 {
  /* Votre style actuel pour l'overlay de la modale */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
}
